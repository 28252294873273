import React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { SiteProvider } from "./context";
import theme from "./styles/theme";

const Style = createGlobalStyle`
  * { box-sizing: border-box; }
  body{
    margin:0;
   }
`;
const RootElement = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Style />
    <SiteProvider>{children}</SiteProvider>
  </ThemeProvider>
);
export default RootElement;
