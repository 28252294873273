import React, { useMemo } from "react";

const SiteContext = React.createContext();

export const SiteProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const ref = React.useRef(null);
  const scrollToTop = React.useCallback(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);
  const scrollToAnchor = React.useCallback((id) => {
    const { y } = document.getElementById(id)?.getBoundingClientRect() || {};
    window.scrollTo({ top: y + window.scrollY - 100, behavior: "smooth" });
  }, []);

  const value = useMemo(
    () => ({
      ref,
      scrollPosition,
      setScrollPosition,
      scrollToTop,
      scrollToAnchor,
      containerRef: ref,
    }),
    [scrollPosition, scrollToAnchor, scrollToTop]
  );

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

export const useSiteConfig = () => React.useContext(SiteContext);
